<template>
  <div :class="`${suwDesktop ? 'section container' : ''}`">
    <EventSeriesCoordinator is-editing @edit-cancelled="onEditCancelled"/>
  </div>
</template>

<script>
import EventSeriesCoordinator from "@/components/eventSeries/EventSeriesCoordinator.vue";

export default {
  name: "EventCreate",
  metaInfo: {
    title: "Writing Groups - "
  },
  components: {
    EventSeriesCoordinator
  },
  methods: {
    onEditCancelled() {
      return this.$router.go(-1);
    },
  }
};
</script>
